<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Create New Bill</h4>
      </CCol>
      <CCol sm="8"></CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
                <form v-on:submit.prevent="handleSubmit(storePrice)" class="p-4">
                  <div v-if="serverErrors">
                    <CAlert color="danger" v-for="err in serverErrors" :key="err">
                      {{ err }}.
                    </CAlert>
                  </div>

                  <div v-if="!$route.params.id" class="row mb-2">
                    <div class="col-md-9 text-center actions">
                      <input
                          type="search"
                          class="form-control"
                          v-model="search"
                          placeholder="Search by A/C Number or Meter No or Mobile No"
                          @keyup.enter="searchMeter()"
                          required
                      />
                    </div>
                    <div class="col-md-3">
                      <button type="button" class="btn btn-primary" @click="searchMeter()">
                        Search
                      </button>
                    </div>
                  </div>
                  <div class="row" v-if="bill.customer_account_id">
                    <div class="col-md-4">
                      <ValidationProvider vid="customer_name" name="Customer Name" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Customer Name</label>
                          <input readonly type="text" class="form-control" v-model="bill.customer_name"
                                 :class=" errors.length ? 'is-invalid' : '' "/>
                          <p class="text-danger mt-0 pt-0"> {{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider vid="account_number" name="Account number" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Account number</label>
                          <input readonly type="text" class="form-control" v-model="bill.account_number"
                                 :class=" errors.length ? 'is-invalid' : '' "/>
                          <p class="text-danger mt-0 pt-0"> {{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider vid="bill_number" name="Bill number" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Bill number</label>
                          <input type="text" class="form-control" v-model="bill.bill_number"
                                 :class=" errors.length ? 'is-invalid' : '' "/>
                          <p class="text-danger mt-0 pt-0"> {{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="effective_from" name="Effective From" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Select Month Year</label>
                          <input type="month" class="form-control" v-model="bill.bill_month_year"
                                 :class=" errors.length ? 'is-invalid' : '' "/>
                          <p class="text-danger mt-0 pt-0"> {{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider vid="due_date" name="Due Date" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Due Date</label>
                          <input type="date" class="form-control" v-model="bill.due_date"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="issue_date" name="Issue Date" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Issue Date</label>
                          <input type="date" class="form-control" v-model="bill.issue_date"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="previous_reading" name="Previous reading" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Previous reading</label>
                          <input type="number" class="form-control" v-model="bill.previous_reading"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="previous_reading_date" name="Previous reading date"
                                          :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Previous reading date</label>
                          <input type="date" class="form-control" v-model="bill.previous_reading_date"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>


                    <div class="col-md-4">
                      <ValidationProvider vid="present_reading" name="Present reading" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Present reading</label>
                          <input type="number" class="form-control" v-model="bill.present_reading"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="present_reading_date" name="Present reading date"
                                          :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Present reading date</label>
                          <input type="date" class="form-control" v-model="bill.present_reading_date"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="unit_price" name="Unit price" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Unit price</label>
                          <input type="number" class="form-control" v-model="bill.unit_price"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="min_amount" name="Min amount" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Min amount</label>
                          <input type="number" class="form-control" v-model="bill.min_amount"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider vid="vat" name="Vat" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Vat (%)</label>
                          <input type="number" class="form-control" v-model="bill.vat"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>


                    <div class="col-md-4">
                      <ValidationProvider vid="service_charge" name="Service charge" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Service charge</label>
                          <input type="number" class="form-control" v-model="bill.service_charge"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="surcharge" name="Surcharge" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Surcharge</label>
                          <input type="number" class="form-control" v-model="bill.surcharge"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider vid="total_amount" name="Total amount" :rules="{required: true}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Total amount</label>
                          <input type="number" class="form-control" v-model="bill.total_amount"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider vid="arrear" name="Arrear" :rules="{required: false}"
                                          v-slot="{ errors }">
                        <div class="form-group">
                          <label>Arrear</label>
                          <input type="number" class="form-control" v-model="bill.arrear"
                                 :class=" errors.length ? 'is-invalid' : ''"/>
                          <p class="text-danger mt-0 pt-0">{{ errors[0] }} </p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" class="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "CreateBillComp",
  components: {Multiselect},
  data() {
    return {
      search: '',
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Billing", path: "/bills", isActive: false},
        {text: "New Bill", path: "", isActive: true}
      ],
      bill: {
        customer_account_id: '',
        customer_id: "",
        customer_name: "",
        account_number: "",
        bill_number: "",
        meter_id: "",
        bill_month_year: "",
        due_date: "",
        issue_date: "",
        previous_reading: "",
        previous_reading_date: "",
        present_reading: "",
        present_reading_date: "",
        min_amount: "",
        vat: 15,
        arrear: "",
        unit_price: "",
        service_charge: "",
        surcharge: "",
        total_amount: "",
      },
      serverErrors: []
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  methods: {
    searchMeter() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/admin/meters/search`, {
            q: this.search,
            type: 'bill-create-page'
          }).then(({data}) => {
                this.bill.customer_account_id = data.data.customer_account_id;
                this.bill.customer_id = data.data.customer_id;
                this.bill.customer_name = data.data.customer_name;
                this.bill.account_number = data.data.account_number;
                this.bill.meter_id = data.data.id;
                this.$store.dispatch("Settings/loading", false);
              }, (error) => {
                this.$toastr.e("Meter Info not found.", "Failed!");
                this.$store.dispatch("Settings/loading", false);
              }
          );
    },
    storePrice() {
      this.$store.dispatch("Settings/loading", true);
      let req = this.$route.params.id ? this.$store.dispatch("Bill/update", this.bill) : this.$store.dispatch("Bill/store", this.bill)
      req.then(() => {
            this.$store.dispatch("Settings/loading", false);
            this.$toastr.s(`Bill Successfuly ${this.$route.params.id ? "Updated" : "Created"}`, "Success!");
            this.$router.push("/bills");
          }, (error) => {
            this.serverErrors = [];
            if (error.response.data) {
              this.serverErrors.push(error.response.data.message);
              this.$toastr.e(error.response.data.message, "Failed!");
              if (error.response.data.errors) {
                this.$refs.formValidator.setErrors(error.response.data.errors);
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }
            this.$store.dispatch("Settings/loading", false);
          }
      );
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch("Bill/getBill", this.$route.params.id)
          .then(({data}) => {
            this.bill = data.data
          })
          .catch(error => {
            console.error("Error fetching bill:", error);
          });
    }
  }
};
</script>
