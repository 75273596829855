var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"4"}},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Create New Bill")])]),_c('CCol',{attrs:{"sm":"8"}})],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"p-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storePrice)}}},[(_vm.serverErrors)?_c('div',_vm._l((_vm.serverErrors),function(err){return _c('CAlert',{key:err,attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(err)+". ")])}),1):_vm._e(),(!_vm.$route.params.id)?_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-9 text-center actions"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search by A/C Number or Meter No or Mobile No","required":""},domProps:{"value":(_vm.search)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchMeter()},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',{staticClass:"col-md-3"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.searchMeter()}}},[_vm._v(" Search ")])])]):_vm._e(),(_vm.bill.customer_account_id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"customer_name","name":"Customer Name","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Customer Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.customer_name),expression:"bill.customer_name"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"readonly":"","type":"text"},domProps:{"value":(_vm.bill.customer_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "customer_name", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"account_number","name":"Account number","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Account number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.account_number),expression:"bill.account_number"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"readonly":"","type":"text"},domProps:{"value":(_vm.bill.account_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "account_number", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"bill_number","name":"Bill number","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Bill number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.bill_number),expression:"bill.bill_number"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"text"},domProps:{"value":(_vm.bill.bill_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "bill_number", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"effective_from","name":"Effective From","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select Month Year")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.bill_month_year),expression:"bill.bill_month_year"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"month"},domProps:{"value":(_vm.bill.bill_month_year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "bill_month_year", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"due_date","name":"Due Date","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Due Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.due_date),expression:"bill.due_date"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"date"},domProps:{"value":(_vm.bill.due_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "due_date", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"issue_date","name":"Issue Date","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Issue Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.issue_date),expression:"bill.issue_date"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"date"},domProps:{"value":(_vm.bill.issue_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "issue_date", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"previous_reading","name":"Previous reading","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Previous reading")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.previous_reading),expression:"bill.previous_reading"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.previous_reading)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "previous_reading", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"previous_reading_date","name":"Previous reading date","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Previous reading date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.previous_reading_date),expression:"bill.previous_reading_date"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"date"},domProps:{"value":(_vm.bill.previous_reading_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "previous_reading_date", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"present_reading","name":"Present reading","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Present reading")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.present_reading),expression:"bill.present_reading"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.present_reading)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "present_reading", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"present_reading_date","name":"Present reading date","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Present reading date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.present_reading_date),expression:"bill.present_reading_date"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"date"},domProps:{"value":(_vm.bill.present_reading_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "present_reading_date", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"unit_price","name":"Unit price","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Unit price")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.unit_price),expression:"bill.unit_price"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.unit_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "unit_price", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"min_amount","name":"Min amount","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Min amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.min_amount),expression:"bill.min_amount"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.min_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "min_amount", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"vat","name":"Vat","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Vat (%)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.vat),expression:"bill.vat"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.vat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "vat", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"service_charge","name":"Service charge","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Service charge")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.service_charge),expression:"bill.service_charge"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.service_charge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "service_charge", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"surcharge","name":"Surcharge","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Surcharge")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.surcharge),expression:"bill.surcharge"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.surcharge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "surcharge", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"total_amount","name":"Total amount","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Total amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.total_amount),expression:"bill.total_amount"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.total_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "total_amount", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"arrear","name":"Arrear","rules":{required: false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Arrear")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bill.arrear),expression:"bill.arrear"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number"},domProps:{"value":(_vm.bill.arrear)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bill, "arrear", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0])+" ")])])]}}],null,true)})],1)]):_vm._e(),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Save ")])])])])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }